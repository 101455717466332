// require("dotenv/config");
function createCanonicalLink() {
  const linkNew = document.createElement("link");
  linkNew.setAttribute("rel", "canonical");
  linkNew.setAttribute("href", `${process.env.CANONICAL_URL}`);
  document.head.appendChild(linkNew);
}

createCanonicalLink();

window.createCanonicalLink = createCanonicalLink